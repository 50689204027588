import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ButtonLoader from '../../../../utils/components/ButtonLoader';
import {makeApiCallPost} from '../../../../Api/makeApiCall';
import FtpConfigButtons from './FtpConfigButtons';
import {RerunCleanseButton} from '../../../Retailer/RerunCleanseButton';
import {BulkLoadArchiveButton, bulkLoadOptionsEnum} from '../../../Retailer/BulkLoadArchiveButton';
import {CleanseSalesToParquetButton} from '../../../Retailer/CleanseSalesToParquetButton';
import {FillAllForRetailer} from '../../../Retailer/FillAllForRetailer';
import ReactTablePopoverMenu from "../../../Components/ReactTable/ReactTablePopoverMenu";

const IngestMappingButtons = ({original, skipFillRetailerIds, fillAllDates, isActive, fillIncludePlu}) => {
    const ftpName = original?.name;
    const retailerId = original?.retailerId;
    const [isSystemPopoverOpen, setIsSystemPopoverOpen] = useState(false);
    const [isLoadingPopoverOpen, setIsLoadingPopoverOpen] = useState(false);
    const [isConfigPopoverOpen, setIsConfigPopoverOpen] = useState(false);
    

    function loadingClickAction(action) {
        if (action === true)
        {
            setIsConfigPopoverOpen(false)
            setIsSystemPopoverOpen(false)
            setIsLoadingPopoverOpen(true)
        }
        else
            setIsLoadingPopoverOpen(false)
    }

    function systemClickAction(action) {
        if (action === true)
        {
            setIsConfigPopoverOpen(false)
            setIsSystemPopoverOpen(true)
            setIsLoadingPopoverOpen(false)
        }
        else
            setIsSystemPopoverOpen(false)
    }

    function configClickAction(action) {
        if (action === true)
        {
            setIsConfigPopoverOpen(true)
            setIsSystemPopoverOpen(false)
            setIsLoadingPopoverOpen(false)
        }
        else
            setIsConfigPopoverOpen(false)

    }

    return (
        <div className={'horizontal-control-block'}>
            {original && retailerId && <>
                <div className='horizontal-control-block-element'>
                    <FtpConfigButtons ftp={ftpName} isConfigPopOverOpen={isConfigPopoverOpen} setIsConfigPopOverOpen={configClickAction}/>
                </div>
                <div className='horizontal-control-block-element'>
                    <ReactTablePopoverMenu id={`load-po-${ftpName}`} title={'Load'} isPopOverOpen={isLoadingPopoverOpen}
                                           setIsPopOverOpen={loadingClickAction}
                    >
                        <div className={"ftp-config-load-menu-buttons"}>
                            <BulkLoadArchiveButton retailerId={retailerId} fileType={bulkLoadOptionsEnum.sales}
                                                   setIsPopOverOpen={loadingClickAction}/>
                        </div>
                        <div className={"ftp-config-load-menu-buttons"}>
                            <CleanseSalesToParquetButton retailerId={retailerId} setIsPopOverOpen={loadingClickAction}/>
                        </div>
                        <div className={"ftp-config-load-menu-buttons"}>
                            <BulkLoadArchiveButton retailerId={retailerId} fileType={bulkLoadOptionsEnum.items}
                                                   setIsPopOverOpen={loadingClickAction}/>
                        </div>
                        <div className={"ftp-config-load-menu-buttons"}>
                            <ButtonLoader buttonTitle={'Flush Incoming'}
                                          buttonHasModal
                                          modalHeader={'Flush incoming?'}
                                          playSound
                                          buttonClassName={'button tertiary'}
                                          modalText={`You are about to flush incoming files for retailer Id: ${retailerId}`}
                                          url={`${process.env.REACT_APP_EDP_API_BASE_URL}/retriggerIngestByFtpFolder/?ftp=${ftpName}`}
                                          onClose={loadingClickAction}
                                          onSubmitCallback={loadingClickAction}
                            />
                        </div>
                    </ReactTablePopoverMenu>
                </div>
                <div className="horizontal-control-block-element rerun-cleanse">
                    <RerunCleanseButton retailerId={retailerId}/>
                </div>
                <div className='horizontal-control-block-element run-fill'>
                    <FillAllForRetailer retailerId={retailerId} active={isActive}
                                        skipFillRetailerIds={skipFillRetailerIds} fillAllDates={fillAllDates}
                                        fillIncludePlu={fillIncludePlu}/>
                </div>
                <ReactTablePopoverMenu id={`system-po-${ftpName}`}
                                       title={'System'}
                                       isPopOverOpen={isSystemPopoverOpen}
                                       setIsPopOverOpen={systemClickAction}
                >
                    <div className='horizontal-control-block-element stage-to-archive-button'>
                        <ButtonLoader buttonTitle={'Stage to Archive'}
                                      url={`${process.env.REACT_APP_EDP_API_BASE_URL}/ftpBulkStageToArchive/`}
                                      buttonHasModal
                                      modalHeader={`Stage to Archive: Retailer ${retailerId}`}
                                      modalText={`Are you sure you want to stage to archive for retailer ${retailerId}?`}
                                      axiosFunction={makeApiCallPost}
                                      postBody={
                                          {
                                              retailerId: retailerId,
                                              ftp: ftpName
                                          }
                                      }
                                      buttonClassName={'button tertiary'}
                                      toastTextFailure={`Stage to Archive for Retailer [${retailerId}] and FTP [${ftpName}] has failed to kicked off.`}
                                      toastTextSuccess={`Stage to Archive for Retailer [${retailerId}] and FTP [${ftpName}] has successfully kicked off.`}
                                      onClose={systemClickAction}
                                      onSubmitCallback={systemClickAction}
                        />
                    </div>
                </ReactTablePopoverMenu>
            </>}
        </div>
    )
}

IngestMappingButtons.defaultProps = {
    fillIncludePlu: false
}

IngestMappingButtons.propTypes = {
    original: PropTypes.shape({
        name: PropTypes.string.isRequired,
        retailerId: PropTypes.number.isRequired,
        active: PropTypes.bool,
        salesAggregationLevel: PropTypes.string
    }),
    skipFillRetailerIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    fillAllDates: PropTypes.bool.isRequired,
    fillIncludePlu: PropTypes.bool,
};

export default IngestMappingButtons;
