import Page from "../Components/Page";
import React, {useState} from "react";
import {makeApiCall} from "../../Api/makeApiCall";
import OptionalTextBox from "../Scaffolding/OptionalTextBox";
import {toast} from "react-toastify";
import PeriodsDropdown from "../Components/Dropdowns/PeriodsDropdown";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import {labelCurrentPeriodWeek, makeApiCallForPeriods} from "../Components/periodDropdownMethods";
import {toastError, toastSuccess} from "../../utils/toast";
import {invokeDAGviaApiPost} from "../../Api/invokeDag";
import AirflowDagLogs from "../Components/airflow/dagLogs/AirflowDagLogs";
import {getValueFromOptions} from "../../utils/maybeAddLabelToOptions";
import SelectDropdown from "../Components/Dropdowns/SelectDropdown";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import OptionalDropDown from "../Scaffolding/OptionalDropDown";
import {cleanseFillExtractTablesQuery} from "../Spotlight/ExtractSpotlight/ExtractSpotlightPage";
import CollapsableContent from "../Components/CollapsableContent";

export const CLEANSE_FILL_CURRENT_COMPARISON_REPORT = "cleanse_fill_current_comparison_report"

export const ComparisonReportPage = () => {

    const fromSchema = undefined
    const [usePeriod, updateUsePeriod] = useState(false);
    const [period, updatePeriod] = useState(undefined);
    const [gcsPath, setGcsPath] = useState('');
    const [extractRunIdentifier, setExtractRunIdentifier] = useState(undefined);
    const [useStandardDeviation, updateUseStandardDeviation] = useState(false);
    const [useThresholdValue, updateUseThresholdValue] = useState(false);
    const [thresholdValue, updateThresholdValue] = useState(undefined);
    const [standardDeviation, updateStandardDeviation] = useState(undefined);
    const [periodsOptions, updatePeriodsOptions] = useState([]);
    const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [useStartingWeekId, updateUseStartingWeekId] = useState(false);
    const [retailerIds, updateRetailerIds] = useState('');
    const [useRetailerIds, updateUseRetailerIds] = useState(false);
    const [storeIds, updateStoreIds] = useState('');
    const [useStoreIds, updateUseStoreIds] = useState(false);
    const [startingWeekId, updateStartingWeekId] = useState(undefined);
    const [insightsStartDate, updateInsightsStartDate] = useState(undefined);
    const [schemaOptions, setSchemaOptions] = useState(undefined);
    const [comparisonSchemas, setComparisonSchemas] = useState([]);
    const [useCustomGCSPath, setUseCustomGCSPath] = useState(false);
    const [loading, setSetLoading] = useState(true);

    function onSubmitButtonClicked() {
        const data =
        {
            "extract_run_identifier": extractRunIdentifier,
            "report_output_gcs_path": gcsPath
        }
        if (usePeriod){
            data['period'] = period;
        }
        if (useStandardDeviation){
            data['std_deviations'] = parseInt(standardDeviation, 10);
        }
        if (useThresholdValue){
            data['pct_diff_threshold'] = parseInt(thresholdValue,10);
        }
        if (useRetailerIds){
            data['retailers_to_exclude'] = retailerIds.split(',').map(Number);
        }
        if (useStoreIds){
            data['stores_to_exclude'] = storeIds.split(',').map(Number);
        }
        if (useStartingWeekId){
            data['start_week_id'] = startingWeekId;
        }



        const dagToRun = CLEANSE_FILL_CURRENT_COMPARISON_REPORT
        invokeDAGviaApiPost(dagToRun, data)
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `${dagToRun} DAG trigger failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `${dagToRun} DAG triggered successfully with response ${JSON.stringify(response)}`)
            })
            .catch(e => {
                toastError(toast, `${dagToRun} DAG trigger failed with error ${JSON.stringify(e)}`)
            })
    }

    React.useEffect(() => {
        setSetLoading(true);
        Promise.all([
            makeApolloClientCall(cleanseFillExtractTablesQuery),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/extractSpotlightMetadata/`),
            makeApiCallForPeriods()
        ])
            .then(([
                       schemasForTableQueryResults,
                       schemasForComparisonsTableQueryResults,
                       periodsResults
                   ]) => {


                const periodsMap = labelCurrentPeriodWeek(periodsResults)
                updatePeriodsOptions(periodsMap)
                schemasForTableQueryResults && schemasForTableQueryResults.tablesInSchema ?
                    setSchemaOptions(schemasForTableQueryResults.tablesInSchema.map(
                        (s) => s.tableName
                    ).filter(
                        tableName => tableName.includes('__')
                    ).filter(
                        tableName => tableName.endsWith('_cleanse_fill_extract')
                    ).map(
                        runIdentifier => ({
                            value: runIdentifier.replaceAll('_cleanse_fill_extract', ''),
                            label: runIdentifier.replaceAll('_cleanse_fill_extract', '')
                        })
                    )) :
                    setSchemaOptions([])
                setComparisonSchemas(schemasForComparisonsTableQueryResults || [])


                setSetLoading(false);
            })
            .catch((error) => {
                console.error(error)
                this.setState({loading: false})
            })
            .finally(() => setSetLoading(false))

    }, []);

    const expandedContent = <>
        <div className="checkbox-container scaffolding-dag">
            <input className="checkbox test"
                   type="checkbox"
                   id="period-checkbox"
                   checked={usePeriod}
                   onChange={() => updateUsePeriod(!usePeriod)}
            />
            <label className={'checkbox-label'}
                   htmlFor="period-checkbox">Choose a period</label>
        </div>
        <PeriodsDropdown
            id={"market_period"}
            isDisabled={!usePeriod}
            className={'data-row-dropdown'}
            updatePeriod={updatePeriod}
            periodLabel={'Period'}
            selectedPeriod={period}
            graphqlQuery={'{ marketPeriodList }'}/>
        <br/>
        <div className={'id-input width-450'}>
            <OptionalDropDown
                checkboxText={'Use an optional Start Week ID for the dataset you create'}
                labelText={'Start Week ID'}
                helpText={`Defaults to the first week of the"insights start" period: ${insightsStartDate}`}
                selectedDropDownValue={getValueFromOptions(startingWeekId && startingWeekId.toString(), periodsOptions)}
                checkBoxIsChecked={useStartingWeekId}
                onCheckBoxChange={updateUseStartingWeekId}
                onDropDownChange={updateStartingWeekId}
                dropDownValues={periodsOptions}
                placeholder={"Select..."}
            />
        </div>
        <br/>
        <div>
            <OptionalTextBox
                labelText={'Store IDs to Exclude'}
                checkboxText={'Input a comma separated list of Store Ids'}
                checkBoxIsChecked={useStoreIds}
                textInput={storeIds}
                onCheckBoxChange={updateUseStoreIds}
                onTextBoxChange={(value) => updateStoreIds(value && value.replace(/[^\d,]/g, ''))}
            />
        </div>
        <div>
            <OptionalTextBox
                labelText={'Retailer IDs to Exclude'}
                checkboxText={'Input a comma separated list of Retailer Ids'}
                checkBoxIsChecked={useRetailerIds}
                textInput={retailerIds}
                onCheckBoxChange={updateUseRetailerIds}
                onTextBoxChange={(value) => updateRetailerIds(value && value.replace(/[^\d,]/g, ''))}
            />
        </div>
        <div>
            <OptionalTextBox
                labelText={'Threshold'}
                checkboxText={'Customize Threshold (0-100)'}
                checkBoxIsChecked={useThresholdValue}
                textInput={thresholdValue}
                onCheckBoxChange={updateUseThresholdValue}
                onTextBoxChange={(value) => updateThresholdValue(value)}
            />
        </div>
        <div>
            <OptionalTextBox
                labelText={'Standard Deviation'}
                checkboxText={'Customize Standard Deviation (0-5)'}
                checkBoxIsChecked={useStandardDeviation}
                textInput={standardDeviation}
                onCheckBoxChange={updateUseStandardDeviation}
                onTextBoxChange={(value) => updateStandardDeviation(value)}
            />
        </div>
    </>;
    return (
        <Page name={'Comparison Report'}>
            <LoadingWrapper loading={loading}>
                <div className={'dag-trigger-group'}>
                    <div className={"custom-run-selections"}>
                        <div className={"custom-run-selections-column"}>
                            <div className={"custom-run-selections-row"}>
                                <div className={"custom-run-selections-row-column"}>

                                    <div className={'schema-field'}>
                                        {<SelectDropdown
                                            id={"from-schema_dropdown"}
                                            className={'data-row-dropdown'}
                                            onChange={(option) => {
                                                const value = option ? option.value : undefined;
                                                if (value) {
                                                    setExtractRunIdentifier(value);
                                                    setGcsPath(`gs://spins-edp-${process.env.REACT_APP_ENV}-tmp/cleanse_fill_current_comparison_report/comparisons_reports_complete_`.concat(value, ".csv"))
                                                    setSubmitButtonDisabled(false);
                                                } else {
                                                    setSubmitButtonDisabled(true);
                                                }


                                            }}
                                            options={schemaOptions}
                                            selectValue={fromSchema}
                                            isClearable={false}
                                            inputLabel={'Extract Identifier'}
                                            placeholder={"Select schema..."}
                                        />}
                                    </div>
                                    <OptionalTextBox
                                        checkboxText={`Choose Custom GCS Path for Comparison Report`}
                                        labelText={'Report GCS Path'}
                                        helpText={gcsPath ? gcsPath : ' '}
                                        onCheckBoxChange={setUseCustomGCSPath}
                                        unsetValueOnUnCheck={false}
                                        onTextBoxChange={(value) => setGcsPath(value)}
                                        checkBoxIsChecked={useCustomGCSPath}
                                        textInput={gcsPath}
                                    />
                                    <br/>
                                    <CollapsableContent expandedContent={expandedContent}
                                                        collapsedContent={"Expand for Advanced Options"}/>
                                    <br/>
                                    <div className={"trigger-dag-submit-button-container"}>
                                        <button type="button"
                                                disabled={isSubmitButtonDisabled}
                                                className="button primary measures-trigger-submit"
                                                onClick={onSubmitButtonClicked}
                                        >
                                            Submit
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </LoadingWrapper>
            <br/>
            <AirflowDagLogs
                processName={CLEANSE_FILL_CURRENT_COMPARISON_REPORT}
                includeDagName={false}
                customColumns={[
                    {
                        header: 'output file',
                        accessorKey: 'conf.report_output_gcs_path',
                        cell: ({getValue}) => <a href={getValue().replace("gs://", "https://storage.cloud.google.com/")} target="_blank">file link</a>,
                    }
                ]}
            />

        </Page>
    );
}

export default ComparisonReportPage;
