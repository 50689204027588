import React, {useState} from 'react';
import Page from '../../Components/Page';
import {columns} from './ContinuityTable';
import _ from 'lodash';
import ApiGetContainer from '../../../Api/ApiGetContainer';
import DynamicColumns from './DynamicColumns';
import {useQuery} from '@apollo/client';
import gql from 'graphql-tag';
import moment from 'moment';
import {sortAscendingWithNullsFirst} from '../../../utils/sorting';
import Checkbox from "../../Components/Checkbox";

export function getWhatWillFillForRetailerQuery(timeServiceResponse, fillIncludePlu) {
    const startWeekId = timeServiceResponse.data.convertDateToPeriod[0].weekId;
    const today = moment(Date.now()).format('yyyy-MM-DD');
    return `
    query{
        data:storeSalesDateValidation(skipFillChecks: false, startWeekId: ${startWeekId}, fillEndDate: "${today}", includePlu: ${fillIncludePlu}){
            retailerId
            dates:datesToFill{
                weekEndDate
            }
            storeId
        }
    }
`
}

export function getDynamicColumns({data}) {
    const allDates = [];
    data.forEach(store => {
        store.dates.forEach(date => {
            allDates.push(date);
        });
    });
    const uniqueSortedDates = _.uniq(allDates).sort().reverse();

    return uniqueSortedDates.map(date => {
        return {
            id: date,
            header: date,
            enableColumnFilter: false,
            sortingFn: sortAscendingWithNullsFirst,
            accessorFn: (retailer) => {
                const possiblyFoundDate = retailer.dates.find(rowDate => rowDate === date);
                return possiblyFoundDate ? 'X' : '';
            }
        }
    });
}

export function reduceByStoreId({data}) {
    const startDate = moment(Date.now()).add(-5, 'weeks').format('yyyy-MM-DD');
    const getDatesFromCurrentStore = currentValue => currentValue.dates.map(date => date.weekEndDate).filter(date => date >= startDate);
    return {
        data: data.reduce((accumulator, currentValue) => {
            let possiblyFoundRetailerAndStore = accumulator.find(retailerAndStore =>
                retailerAndStore.retailerId === currentValue.retailerId &&
                retailerAndStore.storeId === currentValue.storeId);

            if (possiblyFoundRetailerAndStore) {
                possiblyFoundRetailerAndStore.dates = possiblyFoundRetailerAndStore.dates
                    .concat(getDatesFromCurrentStore(currentValue));
            } else {
                const datesFromCurrentStore = getDatesFromCurrentStore(currentValue);
                !_.isEmpty(datesFromCurrentStore) && accumulator.push({
                    retailerId: currentValue.retailerId,
                    storeId: currentValue.storeId,
                    dates: datesFromCurrentStore
                })
            }
            return accumulator;
        }, [])
    }
}

export const convertDateToPeriodQuery = gql`
    query convertDateToPeriod ($date: String!) {
        convertDateToPeriod(date:$date){
            weekId
        }
    }`;

const WhatWillFillPage = () => {
    const [fillIncludePlu, setFillIncludePlu] = useState(true);

    const startWeekId = useQuery(
        convertDateToPeriodQuery,
        {
            variables: {
                date: moment(Date.now()).add(-365, 'days').format('yyyy-MM-DD')
            },
        },
    );
const updateIncludePlu = () => {
            setFillIncludePlu(!fillIncludePlu)
    }

    return (
        <Page name="What Will Fill">
            <div className={'what-will-fill-selection-group'}>
                <div className={'column'}>
                    <div className={'horizontal-control-block-element-left'}>
                        <Checkbox checked={fillIncludePlu}
                                  labelText='Include Plu'
                                  onChange={updateIncludePlu}
                        />
                    </div>
                </div>
            </div>

            {startWeekId?.data && <ApiGetContainer
                graphqlQuery={getWhatWillFillForRetailerQuery(startWeekId, fillIncludePlu)}
                useApolloCache={true}
                componentToRender={DynamicColumns}
                dataPreProcess={reduceByStoreId}
                componentToRenderProps={
                    {
                        dynamicColumnsFunc: getDynamicColumns,
                        columns: columns,
                    }
                }
            />}
        </Page>
    );
};
export default WhatWillFillPage;