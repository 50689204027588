import {useEffect, useState} from "react";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import moment from "moment";
import 'moment-timezone';

export const DEFAULT_DATE_FILTER_OPTION = 'last52Weeks'

export const DATE_FILTER_OPTIONS = {
    last4Weeks: "Last 4 Weeks",
    last8Weeks: "Last 8 Weeks",
    last12Weeks: "Last 12 Weeks",
    last26Weeks: "Last 26 Weeks",
    last52Weeks: "Last 52 Weeks",
    custom: 'Custom'
}

export const dateOptions = [
    {label: "Last 4 Weeks", value: "last4Weeks"},
    {label: "Last 8 Weeks", value: "last8Weeks"},
    {label: "Last 12 Weeks", value: "last12Weeks"},
    {label: "Last 26 Weeks", value: "last26Weeks"},
    {label: "Last 52 Weeks", value: "last52Weeks"},
    {label: 'Custom', value: 'custom'}
];

export const DEFAULT_OPTION = [{label: 'All', value: 'All'}];

export const CHICAGO_TIMEZONE = "America/Chicago";

export const todayChicago = () => moment(Date.now()).tz(CHICAGO_TIMEZONE)

export const formatDate = (momentDate) => momentDate.format('yyyy-MM-DD')

export function getFilterDateRange(selectedDateFilter, fromDate, toDate) {
    if (selectedDateFilter.toLowerCase() === 'custom') {
        return [fromDate, toDate || formatDate(todayChicago())]
    }

    const numberOfWeeks = Number(selectedDateFilter.toLowerCase().replace("last", "").replace("weeks", ""));
    const today = todayChicago()
    const formattedEndDate = formatDate(today)
    const formattedStartDate = formatDate(today.subtract(numberOfWeeks, 'weeks'))
    return [formattedStartDate, formattedEndDate]
}

export const getLastWeekday = (d, daysAgo = 0, weekDayToGet = 0) => {
    const t = new Date(d);
    t.setDate(t.getDate() - daysAgo);
    t.setDate(t.getDate() - t.getDay() + weekDayToGet);
    return t.toISOString();
}

export const getMinFromDate = (toDate) => {
    return formatDate(moment(Date.parse(toDate)).subtract(52, 'weeks'))
}

export const ALL_FILTER_OPTION = 'All'
export const DEFAULT_OPTIONS_FILTER = ALL_FILTER_OPTION;
export const NULL_FILTER_VALUE = 'blank'

export const QUERYSTRING_PARAM_SELECTED_DATE_OPTION = 'selectedDateOption'
export const QUERYSTRING_PARAM_RETAILER_ASSOCIATION = 'retailerAssociation'
export const QUERYSTRING_PARAM_WHOLESALER = 'wholesaler'
export const QUERYSTRING_PARAM_ACCOUNTMANAGER = 'accountManager'
export const QUERYSTRING_PARAM_POSSYSTEM = 'posSystem'
export const QUERYSTRING_PARAM_COMPANYTYPE = 'companyType'
export const QUERYSTRING_PARAM_FROM = 'fromDate'
export const QUERYSTRING_PARAM_TO = 'toDate'
export const QUERYSTRING_PARAM_POS_SUBMISSION_TYPE = 'posSubmissionType'
export const QUERYSTRING_PARAM_POS_VENDOR = 'posVendor'

export const isCustomDateFilterValue = (dateFilterValue) => {
    return (dateFilterValue || '').toLowerCase() === 'custom';
}

export const SALESFORCE_RETAILERS_QUERY = '{salesforceRetailers(isActive: true) {retailerAssociation, wholesaler, accountManager, posSystem, companyType, posSubmissionType, posVendor}}'

export function useLoadOptions() {
    const [loadingOptions, setLoadingOptions] = useState(true)
    const [retailerAssociations, setRetailerAssociations] = useState(undefined)
    const [wholesalers, setWholesalers] = useState(undefined)
    const [accountManagers, setAccountManagers] = useState(undefined)
    const [posSystems, setPosSystems] = useState(undefined)
    const [companyTypes, setCompanyTypes] = useState(undefined)
    const [posSubmissionType, setPosSubmissionType] = useState(undefined)
    const [posVendor, setPosVendor] = useState(undefined)

    useEffect(() => {
        makeApolloClientCall(SALESFORCE_RETAILERS_QUERY)
            .then((data) => {
                const uniqueRetailerAssociations = [...new Set(
                    data.salesforceRetailers.map(retailer => retailer.retailerAssociation || NULL_FILTER_VALUE)
                )].sort();
                const uniqueWholesalers = [...new Set(
                    data.salesforceRetailers.map(retailer => retailer.wholesaler || NULL_FILTER_VALUE)
                )].sort();
                const uniqueAccountManagers = [...new Set(
                    data.salesforceRetailers.map(retailer => retailer.accountManager || NULL_FILTER_VALUE)
                )].sort();
                const uniquePosSystem = [...new Set(
                    data.salesforceRetailers.map(retailer => retailer.posSystem || NULL_FILTER_VALUE)
                )].sort();
                const uniqueCompanyTypes = [...new Set(
                    data.salesforceRetailers.map(retailer => retailer.companyType || NULL_FILTER_VALUE)
                )].sort();
                const uniquePosSubmissionType = [...new Set(
                    data.salesforceRetailers.map(retailer => retailer.posSubmissionType || NULL_FILTER_VALUE)
                )].sort();
                const uniquePosVendor = [...new Set(
                    data.salesforceRetailers.map(retailer => retailer.posVendor || NULL_FILTER_VALUE)
                )].sort();
                setRetailerAssociations(uniqueRetailerAssociations);
                setWholesalers(uniqueWholesalers);
                setAccountManagers(uniqueAccountManagers);
                setPosSystems(uniquePosSystem);
                setCompanyTypes(uniqueCompanyTypes);
                setPosSubmissionType(uniquePosSubmissionType);
                setPosVendor(uniquePosVendor);
                setLoadingOptions(false);
            });
    }, []);
    return {loadingOptions, retailerAssociations, wholesalers, accountManagers, posSystems, companyTypes, posSubmissionType, posVendor}
}

export const getFilterOptions = (filters) => [
    ALL_FILTER_OPTION,
    ...(filters || [])
]

