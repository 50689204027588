import React from 'react';
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import Page from "../Components/Page";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {EditFormDropdownInputField, EditFormLabelErrorWrapper} from "../Components/ReactTable/EditRecordForm";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";


export const STANDARDIZED_UNIT_OF_MEASURE_DROPDOWN_OPTIONS = [
    {value: "COUNT", label: "COUNT"},
    {value: "EACH", label: "EACH"},
    {value: "OUNCE", label: "OUNCE"},
    {value: "PINTS", label: "PINTS"},
    {value: "POUND", label: "POUND"},
    {value: "QUARTS", label: "QUARTS"}
]

export function getStandardizedField(displayedUnit) {
    const regex = /([a-zA-Z/#]+)/;
    const inputValue = displayedUnit ? displayedUnit.replaceAll(" ", "").toUpperCase() : ''
    const result = regex.exec(inputValue);
    return result ? result[1] : '';
}

function StandardizeField({id, form}) {
    const displayedUnit = form.watch("displayedUnit")
    form.setValue(id, getStandardizedField(displayedUnit))

    return (
        <EditFormLabelErrorWrapper id={id} form={form} label={'Value used for Standardizing Parsed Unit of Measure'}>
            <input
                type={"text"}
                id={id}
                disabled={true}
                readOnly={true}
                step={"any"}
                {...(form.register(id))}/>
        </EditFormLabelErrorWrapper>
    );
}

function DisplayedUnitField({id, form}) {
    const isRequired = true;
    return (
        <EditFormLabelErrorWrapper id={id} form={form} label={'Display Units'} isRequired={isRequired}>
            <input
                type={"text"}
                id={id}
                disabled={false}
                readOnly={false}
                step={"any"}
                onKeyUp={(e) => {
                    e.target.value = e.target.value ? e.target.value.toUpperCase() : "";
                }}
                {...(form.register(id, {required: isRequired}))}/>
        </EditFormLabelErrorWrapper>
    );
}

const StandardizedUnitOfMeasureService = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/standardizedUnitOfMeasure`;
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    function validatePreSave({form}) {
        const displayedUnits = getStandardizedField(form.getValues('displayedUnit'));
        const index = form.getValues('index');
        const currentDisplayedUnits = data.data
            .filter(item => item.index !== index)
            .map(item => getStandardizedField(item.displayedUnit));

        const errors = []
        if (currentDisplayedUnits.includes(displayedUnits)) {
            errors.push("Displayed Units already exists.")
        }
        if (errors.length > 0) {
            form.setError('displayedUnit', {type: 'custom', message: errors});
        }

        return errors.length === 0;
    }

    async function getOnRowEditConfirmedWrapper(newOrUpdatedRow) {
        delete newOrUpdatedRow.standardizeField;
        await onRowEditConfirmed(newOrUpdatedRow);
    }

    return (
        <Page name={"Standardized Unit Of Measures"}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={[
                        {header: "Standardized Units", id: "standardizedUnit", accessorKey: "standardizedUnit"},
                        {header: "Display Units", id: "displayedUnit", accessorKey: "displayedUnit"}]
                    }
                    editTable={[
                        INDEX_FIELD,
                        {id: 'standardizedUnit', Component: args => <EditFormDropdownInputField {...args} options={STANDARDIZED_UNIT_OF_MEASURE_DROPDOWN_OPTIONS} isRequired/>},
                        {id: 'displayedUnit', Component: args => <DisplayedUnitField {...args} />},
                        {id: 'standardizeField', Component: args => <StandardizeField {...args} />},
                    ]}
                    initialState={{pagination: {pageSize: 25}}}
                    validatePreSave={validatePreSave}
                    onRowEditConfirmed={getOnRowEditConfirmedWrapper}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>
        </Page>
    );
};

export default StandardizedUnitOfMeasureService;