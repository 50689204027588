import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";

const Checkbox = ({
                      extraLabelClassName,
                      checked,
                      onChange,
                      labelText
                  }) => {
    const id = _.kebabCase(`checkbox-${labelText}`)
    let className = `checkbox-label ${extraLabelClassName}`.trim();

    return <div className={'checkbox-container'} data-testid={`checkbox-container-${id}`}>
        <input className={'checkbox'}
               data-testid={id}
               type="checkbox"
               id={id}
               checked={checked}
               onChange={() => onChange(!checked)}
        />
        <label className={className} htmlFor={id} data-testid={`${id}-label`}>
            {labelText}
        </label>
    </div>;
};

Checkbox.defaultProps ={
    extraLabelClassName: ''
}

Checkbox.propTypes = {
    extraLabelClassName: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Checkbox;