import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useServiceBase} from "../../Components/ReactTable/useServiceBase";
import {
    INDEX_FIELD,
    RETAILER_ID_FIELD,
    RETAILER_ID_NAME_COLUMN
} from "../../Components/ReactTable/common_custom_columns";
import Page from "../../Components/Page";
import {Breadcrumbs} from "../../Components/Breadcrumbs";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import EditableReactTableV2 from "../../Components/ReactTable/EditableReactTableV2";
import {serviceRetailerDropdown} from "../../../utils/retailerDisplayFormatter";
import {EditFormDropdownInputField, EditFormTextInputField} from "../../Components/ReactTable/EditRecordForm";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import gql from "graphql-tag";
import IngestMappingButtons from "./Columns/IngestMappingButtons";
import {makeApiCall, makeApiCallPost} from "../../../Api/makeApiCall";
import InactiveRowKey from "../../Components/InactiveRowKey";
import Checkbox from "../../Components/Checkbox";
import {getUserEmail} from "../../../auth/accessTokenValidator";
import ButtonLoader from "../../../utils/components/ButtonLoader";

const FtpConfigurationConfigPage = ({displayHelmetHeader, retailerId}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ftpToRetailerIdDecode/${retailerId ? `?retailerId=${retailerId}` : ''}`
    const endpointUrlForPost = `${process.env.REACT_APP_EDP_API_BASE_URL}/ftpToRetailerIdDecode/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl, endpointUrlForPost});

    const [activeRetailers, setActiveRetailers] = useState(undefined);
    const [skipFillRetailers, setSkipFillRetailers] = useState([]);
    const [fillAllDates, setFillAllDates] = useState(false);
    const [fillEndDate, setFillEndDate] = useState(undefined);
    const [fillIncludePlu, setFillIncludePlu] = useState(true);
    const [decodeTablesLoading, setDecodeTablesLoading] = useState(true);

    const columns = [
        RETAILER_ID_NAME_COLUMN,
        {header: "Name", accessorKey: "name"},
        {header: "Aggregation Level", accessorKey: "salesAggregationLevel"},
        {
            header: "Buttons", id: "ingestionButtons", size: 800,
            cell: ({row}) => {
                const isActive = activeRetailers.includes(row.original.retailerId)
                return <div className={"ftp-configs-mapping-buttons"}>
                    <IngestMappingButtons
                        original={row.original}
                        skipFillRetailerIds={skipFillRetailers}
                        fillAllDates={fillAllDates}
                        isActive={isActive}
                        fillIncludePlu={fillIncludePlu}
                    />
                </div>;
            }
        }
    ];
    const aggregationOptions = [
        {label: "daily", value: "daily"},
        {label: "weekly", value: "weekly"},
        {label: "n/a", value: "n/a"}
    ]

    const toggleFillAllDates = () => setFillAllDates(!fillAllDates)
    const toggleFillIncludePlu = () => setFillIncludePlu(!fillIncludePlu)

    useEffect(() => {
        setDecodeTablesLoading(true)
        const retailerIds = retailerId ? [retailerId] : undefined;
        const salesForceRetailersQuery = gql`query salesforceRetailers($retailerIds:[Int]) { salesforceRetailers(retailerIds: $retailerIds) { retailerId active } }`;

        Promise.all([
            makeApolloClientCall(salesForceRetailersQuery, false, {retailerIds}),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/skipFillRetailers/`)
        ])
            .then(([
                       salesforceRetailers,
                       skipFillRetailersService,
                   ]) => {

                const activeRetailersFromSalesForce = salesforceRetailers.salesforceRetailers
                    .filter(r => r.active)
                    .map(r => r.retailerId);

                const skipFillRetailerIds = skipFillRetailersService['data'].map(retailer => retailer.retailerId);

                setActiveRetailers(activeRetailersFromSalesForce)
                setSkipFillRetailers(skipFillRetailerIds)
                setDecodeTablesLoading(false)
            })
    }, []);

    function validatePreSave({form}) {
        const name = form.getValues('name');
        const errors = [
            {regex: new RegExp(/([A-Z])/), message: 'Name can not contain uppercase letters'},
        ].filter(e => e.regex.test(name))
            .map(e => e.message)

        if (errors.length > 0) {
            form.setError('name', {type: 'custom', message: errors});
        }

        return errors.length === 0;
    }


    return (<Page name={"FTP Configurations"}
                  breadCrumbs={<Breadcrumbs items={[{label: "Configurations"}, {label: "Ftp", to: '/ftpConfigurations'}]}/>}
                  displayHelmetHeader={displayHelmetHeader}
        >
            <LoadingWrapper loading={loading || decodeTablesLoading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={columns}
                    initialState={{pagination: {pageSize: 25}, sorting: [{id: "retailerIdName", desc: false}]}}
                    editTable={[
                        INDEX_FIELD,
                        RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                        {id: 'name', Component: args => <EditFormTextInputField {...args} isRequired/>},
                        {id: 'salesAggregationLevel', defaultValue:"weekly", Component: args => <EditFormDropdownInputField {...args} options={aggregationOptions} isRequired/>}
                    ]}
                    getTrProps={(rowInfo) => {
                        let className = ''
                        if (activeRetailers.includes(rowInfo.original.retailerId) === false) {
                            className = 'inactive-retailer-row-ftp'
                        }
                        return {className}
                    }}

                    onRowEditConfirmed={onRowEditConfirmed}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                    className={"unmapped-retailers-table"}
                    validatePreSave={validatePreSave}
                >
                    {
                        ({}) => {
                            return (
                                <div id={'switches'} data-testid={"ftp-config-page-switches"}>
                                    {!retailerId && <div className="run-all-retailer-dags">
                                        <input data-testid={"filter-for-fill-end-date"}
                                               className="fill-end-date"
                                               type="date"
                                               onChange={(event)=> setFillEndDate(event.target.value)}/>
                                        <Checkbox checked={fillAllDates}
                                                  labelText='Refill All History'
                                                  onChange={toggleFillAllDates}/>
                                        <Checkbox checked={fillIncludePlu}
                                                  labelText='Include Plu'
                                                  onChange={toggleFillIncludePlu}/>
                                        <ButtonLoader
                                            buttonTitle={'Fill All Retailers'}
                                            buttonHasModal
                                            modalHeader={'Rerun Fill All Retailers'}
                                            userAcknowledgedCheck
                                            modalText={'Are you sure you want to rerun fill for all retailers?'}
                                            url={`${process.env.REACT_APP_EDP_API_BASE_URL}/runFillAll`}
                                            axiosFunction={makeApiCallPost}
                                            toastTextFailure={`run fill all for all retailers with end date ${fillEndDate} has failed to kicked off.`}
                                            toastTextSuccess={`run fill all for all retailers with end date ${fillEndDate} has successfully kicked off.`}
                                            postBody={{fill_end_date: fillEndDate, fill_all_dates: fillAllDates, triggered_by: getUserEmail(), include_plu: fillIncludePlu}}
                                        />
                                        <ButtonLoader
                                            buttonTitle={'Rerun Cleanse All Retailers'}
                                            buttonHasModal
                                            axiosFunction={makeApiCallPost}
                                            postBody={{triggered_by: getUserEmail()}}
                                            modalHeader={'Rerun Cleanse All Retailers'}
                                            userAcknowledgedCheck
                                            modalText={'Are you sure you want to rerun cleanse for all retailers?'}
                                            url={`${process.env.REACT_APP_EDP_API_BASE_URL}/rerunCleanse`}
                                            toastTextFailure={`Rerun Cleanse for all retailers has failed to kicked off.`}
                                            toastTextSuccess={`Rerun Cleanse for all retailers has successfully kicked off.`}
                                        />
                                        <InactiveRowKey inactiveColorClassName={'inactive-retailer-row-ftp'}/>
                                    </div>}
                                    {retailerId && <Checkbox checked={fillAllDates} labelText='Refill All History'
                                                             onChange={toggleFillAllDates}/>}
                                </div>

                            )
                        }
                    }
                </EditableReactTableV2>
            </LoadingWrapper>
        </Page>
    );
};

FtpConfigurationConfigPage.defaultProps = {
    displayHelmetHeader: true
};

FtpConfigurationConfigPage.propTypes = {
    displayHelmetHeader: PropTypes.bool,
    retailerId: PropTypes.number
};

export default FtpConfigurationConfigPage;