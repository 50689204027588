import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {makeApiCall, makeApiCallPost} from '../../Api/makeApiCall';
import {CONFIG_TYPES, ConfigType} from './FileTypes';
import {getUserEmail} from "../../auth/accessTokenValidator";
import {getMoment} from "../../utils/time";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import {addLabelToOptionsIfNotExist} from "../../utils/maybeAddLabelToOptions";
import SelectDropdown from "../Components/Dropdowns/SelectDropdown";
import _ from "lodash";

class FtpConfigs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            configTypes: undefined,
            value: undefined,
            metadata: undefined,
            originalValue: undefined
        };

        this.loadIngestMapping = this.loadIngestMapping.bind(this);
        this.selectIngestFile = this.selectIngestFile.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (!this.props.isModalMode)
            this.loadFileTypesConfigs()
    }

    closeModal() {
        this.setState({modalIsOpen: false});
        this.props.setIsPopoverOpen(false)
    }

    async updateIngestMapping() {

        let parsedConfig, parsedOriginalConfig;

        try {
            parsedConfig = JSON.parse(this.state.value);
            parsedOriginalConfig = this.state.originalValue && JSON.parse(this.state.originalValue);
        } catch (e) {
            alert('Error: You tried to save invalid JSON.');
            console.error('=============> e: ', e);
            return;
        }

        const shouldAllowToSave = await this.props.configType.shouldSaveConfig(parsedConfig, this.props.ftp, parsedOriginalConfig)
        if (!shouldAllowToSave) {
            return;
        }

        const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ftpConfig/?ftp=${this.state.selectedSalesConfig || this.props.ftp}&configType=${this.props.configType.getName()}`;
        const metadata = this.state.metadata || {}
        if (!metadata['updates']) {
            metadata['updates'] = []
        }

        metadata['updates'].push({user: getUserEmail(), timestamp: getMoment().toISOString()})

        await makeApiCallPost(endpointUrl, JSON.stringify({...parsedConfig, metadata}));
        this.closeModal();
    }

    handleChange(value) {
        this.setState({value});
    }

    async loadFileTypesConfigs() {
        const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ftpConfig/?ftp=${this.props.ftp}&configType=${CONFIG_TYPES.FILE_TYPE.getName()}`;
        const result = await makeApiCall(endpointUrl);
        const selectedConfig = this.prepareConfigTypeMapping(result[`${this.props.configType.getName()}_mappings`]);
        this.setState(
            {
                configTypes: selectedConfig,
                modalIsOpen: this.props.isModalMode,
                selectedSalesConfig: selectedConfig && selectedConfig.length ? selectedConfig[0].value : undefined
            },
            () => this.loadIngestMapping()
        );
    }

    prepareConfigTypeMapping(configTypesMapping) {
        return configTypesMapping && configTypesMapping.length ? configTypesMapping.map(mapping => {
                return {label: mapping.filename, value: mapping.filename.replace('.json', '')};
            }
        ) : undefined;
    }


    async selectIngestFile(selection) {
        if (selection) {
            this.setState(
                {selectedSalesConfig: selection.value},
                () => this.loadIngestMapping()
            );
        }
    }

    loadIngestMapping = async () => {
        const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/ftpConfig/?ftp=${this.state.selectedSalesConfig || this.props.ftp}&configType=${this.props.configType.getName()}`;
        const result = await makeApiCall(endpointUrl);
        let metadata = undefined

        if (result['metadata']) {
            metadata = result['metadata']
            delete result['metadata']
        }

        await this.setState({
            metadata,
            value: JSON.stringify(result, null, 2),
            originalValue: JSON.stringify(result, null, 2),
            modalIsOpen: this.props.isModalMode
        });

    };

    render() {
        const modalContent = <><div data-testid={'modal-content'}><h2>{this.props.configType.getName()} config [{this.props.ftp}]</h2>
            {
                this.state.configTypes &&
                <div className={"modal-ftp-configuration-dropdown"}>
                    <SelectDropdown
                        id={"modal-ftp-configuration_dropdown"}
                        classNamePrefix={"modal-ftp-configuration-dropdown"}
                        options={addLabelToOptionsIfNotExist(this.state.configTypes) || []}
                        selectValue={this.state.selectedSalesConfig}
                        selectLabel={`${this.state.selectedSalesConfig}.json`}//value={{value: this.state.selectedSalesConfig, label: `${this.state.selectedSalesConfig}.json`}}
                        onChange={this.selectIngestFile}
                    />
                </div>
            }

            <AceEditor
                mode="json"
                theme="github"
                onChange={this.handleChange}
                name="ace_editor_01"
                editorProps={{$blockScrolling: true}}
                value={this.state.value}
            />
            <div className={"right-things"}>
                {this.props.isModalMode && <button className="button cancel modal-button" type="button" name="close"
                        onClick={() => this.closeModal()}>
                    Close
                </button>}
                <button className="button primary modal-button" type="button"
                        onClick={() => this.updateIngestMapping(this.state.value)}>
                    Update
                </button>
            </div>
        </div>
        </>;
        return (
            <>
                {!this.props.isModalMode && <div>{modalContent}</div>}
                {
                    this.props.isModalMode && <div data-testid={'modal'} className={"ftp-configs-modal-button"} >
                        <button className="button tertiary mapping"
                                onClick={() => this.loadFileTypesConfigs()}>{this.props.buttonTitle}
                        </button>
                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={this.closeModal}
                            className="Modal ftp-configs-modal"
                            overlayClassName="Overlay"
                            ariaHideApp={false}>
                            {modalContent}
                        </Modal>
                    </div>
                        }
                    </>
                    );
                }
}

FtpConfigs.defaultProps = {
    isModalMode: true,
    setIsPopOverOpen: _.identity
}

FtpConfigs.propTypes = {
    buttonTitle: PropTypes.string,
    ftp: PropTypes.string,
    className: PropTypes.string,
    configType: PropTypes.instanceOf(ConfigType).isRequired,
    isModalMode: PropTypes.bool,
    shouldAllowToSaveConfig: PropTypes.func,
    setIsPopoverOpen: PropTypes.func
};

export default FtpConfigs
