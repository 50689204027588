import _, {round} from "lodash";
import {displayFunctions} from "../StoreTotals/Components/StoreTotalsDataSelections";
import {getStyleForOutlierFunction} from "../StoreTotals/Components/getStyleForOutlierFunction";
import {getHeader} from "./StoreTotalsHeaderComponent";
import {getAccessor} from "./StoreTotalsDataGetAccessor";
import {styleForConfigs} from "./utils";
import {sortAscendingWithNullsFirst} from "../../../utils/sorting";
import {getWatermarkForRetailer} from "./getWatermarkForRetailer";
import {getDollarsFormat} from "../StoreTotals/Components/SummaryDisplayFunctions";
import {getNicelyFormattedNumber} from "../../../utils/formatUtil";


export const detailedSummaryColumns = [
    {
        header: "Store Name",
        accessorKey: "storeName",
        sticky: 'left',
        className: "total-dollar-store-name",
        headerClassName: "total-dollar-store-name",
        minSize: 350,
        headerStyle: {flex: '2 0 auto'},
        cellStyle: {flex: '2 0 auto'},
        footer: <span>Totals:</span>,
    },
    {
        header: "Store ID",
        accessorKey: "storeId",
        className: "total-dollar-store-id",
        sticky: 'left',
        size: 100,
        filterFn: (row, columnIds, filterValue) => !filterValue || Boolean(`${row.original.storeId}`.startsWith(filterValue)),
    }
];

export const getStoreTotalsDataColumns = ({
                                              retailerId,
                                              data,
                                              percentThreshold,
                                              displayDataFunction,
                                              contextMenuId,
                                              selectedData,
                                              setTimestamp,
                                              cellValueClicked,
                                              fillWatermark,
                                              promoWatermark
                                          }) => {
    const dataSelection = displayFunctions[selectedData];
    const expectedDates = _.union(data.sales.rollUpDates, data.fill.rollUpDates);
    const columnDates = _.union(expectedDates, data.sales.weekEndDates, data.fill.weekEndDates).sort().reverse();
    const outlierDatesByStore = data.outliers.data;
    const styleForOutlierFunction = getStyleForOutlierFunction(dataSelection, percentThreshold);
    const stickyColumns = [
        {
            header: "Store",
            sticky: "left",
            columns: [
                ...detailedSummaryColumns,
                {
                    header: "Average",
                    id: "Average",
                    className: "total-dollar-average",
                    size: 100,
                    sticky: 'left',
                    enableColumnFilter: false,
                    enableColumnSort: true,
                    accessorFn: (row) => {
                        const store = row;
                        const maybeData = outlierDatesByStore.find(outlier => (outlier.storeId === store.storeId));
                        if (maybeData) {
                            return `${dataSelection.displayAverageFunction(maybeData)}`;
                        }
                        return '';
                    }
                }
            ]
        }];


    const retailerFillWatermark = getWatermarkForRetailer(fillWatermark, retailerId, "dateFilled");
    const retailerPromoWatermark = getWatermarkForRetailer(promoWatermark, retailerId, "datePromo");
    const millisecondsInDays = 1000 * 60 * 60 * 24;
    const dateGroup = [
        {
            header: "",
            id: 'dates',
            columns: columnDates.map((date) => {
                const headerClassName = expectedDates.includes(date) ? "totals-header" : "totals-header-emphasis";
                return {
                    id: date,
                    footer: (row) => {
                        const isDollars = selectedData.toLowerCase() === 'dollars';
                        const maybeData = data.sales.stores
                            .flatMap(store => store.weekEndData)
                            .filter(weekEndData => (weekEndData.source === 'cleansed' && weekEndData.date === row.column.id))
                            .map(weekEndData => isDollars ? weekEndData.dollars : weekEndData.units);
                        if (maybeData) {
                            const total = _.sum(maybeData);
                            return <span>{isDollars ? getDollarsFormat(total) : getNicelyFormattedNumber(round(total))}</span>;
                        }
                        return <span/>;
                    },
                    header: getHeader(retailerId, date, contextMenuId, setTimestamp, headerClassName,
                        (new Date(date) - new Date(retailerFillWatermark)) < (7 * millisecondsInDays) && (new Date(date) - new Date(retailerFillWatermark)) >= 0 ? retailerFillWatermark : undefined,
                        (new Date(date) - new Date(retailerPromoWatermark)) < (7 * millisecondsInDays) && (new Date(date) - new Date(retailerPromoWatermark)) >= 0 ? retailerPromoWatermark : undefined),
                    size: 120,
                    accessorFn: getAccessor(retailerId, date, outlierDatesByStore, displayDataFunction, styleForOutlierFunction, styleForConfigs, contextMenuId, setTimestamp, cellValueClicked),
                    cell: ({getValue}) => getValue().component,
                    enableColumnFilter: false,
                    enableSorting: true,
                    className: "no-padding",
                    sortingFn: (rowA, rowB, columnId) => {
                        const classNameA = rowA.getValue(columnId).className;
                        const classNameB = rowB.getValue(columnId).className;
                        const primarySort = sortAscendingWithNullsFirst(classNameA.match(/\d/g), classNameB.match(/\d/g));
                        if (primarySort !== 0) {
                            return primarySort;
                        }

                        return rowB.index - rowA.index;
                    }
                };
            })
        }
    ];

    return stickyColumns.concat(dateGroup);
};
