import React, {useEffect, useState} from 'react';
import _ from "lodash"
import {getMoment} from "../../utils/time";
import {SelectableList} from "../Components/SelectableList";
import Checkbox from "../Components/Checkbox";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import {isOnboarding} from '../IngestionLogs/Columns/RetailerIdWithOnboarding';
import {makeApiCall} from "../../Api/makeApiCall";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import {convertValueToCentral} from "../../utils/formatUtil";

function _getTaskCounts(apiResponse, filteredItems) {
    const taskCounts = apiResponse.taskTypes.map(taskNameMapping => {
        const taskName = taskNameMapping.alertId
        return ({
            label: taskNameMapping.displayName,
            value: filteredItems.filter(alert => alert.task_name === taskName).length
        });
    })
    return _.orderBy(taskCounts, ['value', 'label'], ['desc', 'asc'])
}

function _getRetailerCounts(filteredData, salesforceRetailerLookup) {
    const alertsByRetailer = _.groupBy(filteredData, alert => alert.processedPayload?.retailer_id);
    const retailerCounts = Object.entries(alertsByRetailer).map(([retailerId, alerts]) => {
        const onboardingPrefix = isOnboarding(salesforceRetailerLookup[retailerId]) ? `Onboarding: ` : '';
        return ({
            label: `${onboardingPrefix}${retailerId} - ${alerts[0].retailer_chain || ''}`,
            value: alerts.length,
            retailerId
        });
    });
    return _.orderBy(retailerCounts, ['value', 'label'], ['desc', 'asc'])
}

function _getDateCounts(filteredData) {
    const currentDateTime = convertValueToCentral(getMoment())
    return [0, 1, 2, 3, 4, 5, 6]
        .map(daysToSubtract => currentDateTime.clone().subtract(daysToSubtract, "days"))
        .map(dateStartOfDay => ({
            label: dateStartOfDay.format('YYYY-MM-DD'),
            value: filteredData.filter(alert => _isSameDay(convertValueToCentral(alert.timestamp), dateStartOfDay)).length
        }))
}

function _isSameDay(momentToCompare, anchorMoment) {
    return momentToCompare.format('YYYY-MM-DD') === anchorMoment.format('YYYY-MM-DD')
}

function _isStoreInsightsPriorityRetailers(alertIdRetailerId, storeInsightsPriorityRetailers) {
    return storeInsightsPriorityRetailers.includes(parseInt(alertIdRetailerId))
}

function filterRetailers(salesforceRetailerLookup, includeOnlyActive, includeOnlyOnboarding, storeInsightsPriorityRetailers, includeOnlyStoreInsightsPriorityRetailers, alert) {
    const retailerMapNotLoaded = salesforceRetailerLookup === undefined;
    if (retailerMapNotLoaded) {
        return false;
    }

    const anyFiltersSelected = includeOnlyActive || includeOnlyOnboarding || includeOnlyStoreInsightsPriorityRetailers;
    if (!anyFiltersSelected) {
        return true;
    }

    const alertIdRetailerId = alert.processedPayload?.retailer_id;
    const salesforceRetailer = salesforceRetailerLookup[alertIdRetailerId];

    return (includeOnlyActive && salesforceRetailer?.active) ||
        (includeOnlyOnboarding && isOnboarding(salesforceRetailer)) ||
        (includeOnlyStoreInsightsPriorityRetailers && _isStoreInsightsPriorityRetailers(alertIdRetailerId, storeInsightsPriorityRetailers))
}

export const OpsAlertsSummaryContent = ({data}) => {
    const [selectedDate, updateSelectedDate] = useState(undefined)
    const [selectedRetailer, updateSelectedRetailer] = useState(undefined)
    const [selectedTask, updateSelectedTask] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [includeOnlyActive, updateIncludeOnlyActive] = useState(false)
    const [includeOnlyOnboarding, updateIncludeOnlyOnboarding] = useState(false)
    const [includeOnlyStoreInsightsPriorityRetailers, updateIncludeOnlyStoreInsightsPriorityRetailers] = useState(false)
    const [salesforceRetailerLookup, setSalesforceRetailerLookup] = useState({})
    const [storeInsightsPriorityRetailers, setStoreInsightsPriorityRetailers] = useState({})

    const filteredData = data.metadata.filter(alert => filterRetailers(salesforceRetailerLookup, includeOnlyActive, includeOnlyOnboarding, storeInsightsPriorityRetailers, includeOnlyStoreInsightsPriorityRetailers, alert));

    const onSubmit = () => {
        let url = '/alerts/log?days=7'

        if (selectedDate) {
            url += `&timestamp=${selectedDate.label}`
        }

        if (selectedRetailer) {
            url += `&retailerId=${selectedRetailer.retailerId}`
        }

        if (selectedTask) {
            url += `&task_name=${selectedTask.label}`
        }

        window.location.assign(url);
    }

    useEffect(() => {
        setLoading(true);
        const query = `
        query salesforceRetailers {
            salesforceRetailers {
                retailerId
                active
                onboardingStatus
            }
        }`;

        Promise.all([
            makeApolloClientCall(query),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/priority_retailers/`)
        ]).then(([
                     salesForceRetailersResponse,
                     storeInsightsPriorityRetailersResponse
                 ]) => {
            setSalesforceRetailerLookup(_.keyBy(salesForceRetailersResponse.salesforceRetailers, 'retailerId'));
            setStoreInsightsPriorityRetailers(storeInsightsPriorityRetailersResponse.data.map(x => x.retailerId));
        }).finally(() => setLoading(false));

    }, []);
    return (
        <>
            <LoadingWrapper loading={loading}>
                <>
                    <h5>Last 7 days summary</h5>
                    <div className={"retailer-status-group"}>
                        <div className={'retailer-status-checkboxes-container'}>
                            <div className={"retailer-status-label"}>Retailer Status</div>
                            <div id={"check-boxes"}>
                                <Checkbox id={'active'}
                                          labelText={'Active'}
                                          onChange={(isActive) => updateIncludeOnlyActive(isActive)}
                                          checked={includeOnlyActive}/>
                                <Checkbox id={'onboarding'}
                                          labelText={'Onboarding'}
                                          onChange={(isOnboarding) => updateIncludeOnlyOnboarding(isOnboarding)}
                                          checked={includeOnlyOnboarding}/>
                                <Checkbox id={'storeInsightsRetailers'}
                                          labelText={'Store Insights'}
                                          onChange={(isStoreInsightsPriorityRetailers) => updateIncludeOnlyStoreInsightsPriorityRetailers(isStoreInsightsPriorityRetailers)}
                                          checked={includeOnlyStoreInsightsPriorityRetailers}/>
                            </div>
                        </div>
                        <div className={'alert-divider'}/>
                        <div className='fake-button-for-show'>
                            <input type="submit" className='button primary left-margin' value='Submit'
                                   onClick={onSubmit}/>
                        </div>

                    </div>
                    <div className={'alert-summary'}>
                        <div className={'alert-group'} data-testid={'date_group'}>
                            <h6>Date</h6>
                            <SelectableList items={_getDateCounts(filteredData)} onItemSelected={updateSelectedDate}
                                            selected={selectedDate}/>
                        </div>
                        <div className={'alert-group'} data-testid={'retailer_group'}>
                            <h6>Retailer</h6>
                            <SelectableList items={_getRetailerCounts(filteredData, salesforceRetailerLookup)}
                                            onItemSelected={updateSelectedRetailer}
                                            selected={selectedRetailer}/>
                        </div>
                        <div className={'alert-group'} data-testid={'task_group'}>
                            <h6>Task</h6>
                            <SelectableList items={_getTaskCounts(data, filteredData)}
                                            onItemSelected={updateSelectedTask}
                                            selected={selectedTask}/>
                        </div>
                    </div>
                </>
            </LoadingWrapper>
        </>)
};

OpsAlertsSummaryContent.defaultProps = {};

OpsAlertsSummaryContent.propTypes = {};