import Page from "../Components/Page";
import React, {useState} from "react";
import {makeApiCall} from "../../Api/makeApiCall";
import OptionalTextBox from "../Scaffolding/OptionalTextBox";
import {toast} from "react-toastify";
import PeriodsDropdown from "../Components/Dropdowns/PeriodsDropdown";
import OutletsDropdown from "../Components/Dropdowns/OutletsDropdown";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import {currentPeriodQuery} from "../../queries/currentPeriodQuery";
import {labelCurrentPeriodWeek, makeApiCallForPeriods} from "../Components/periodDropdownMethods";
import LastKeithFilesRun from "./LastKeithFilesRun";
import Checkbox from "../Components/Checkbox";
import {toastError, toastSuccess} from "../../utils/toast";
import {prefixFilterRules} from "../../utils/prefixFilterRules";
import CheckpointDropdown from "../Components/CheckpointDropdown";
import {invokeDAGviaApiPost} from "../../Api/invokeDag";
import {insightsStartDateQuery} from "../../queries/insightsStartDate";
import AirflowDagLogs from "../Components/airflow/dagLogs/AirflowDagLogs";
import {isMeasuresBetaAvailable} from "./beta";
import {addLabelToOptionsIfNotExist, getValueFromOptions} from "../../utils/maybeAddLabelToOptions";
import Select from "react-select";
import SelectDropdown from "../Components/Dropdowns/SelectDropdown";
import ReactSelectDropdownFromApi from "../Components/ReactSelectDropdownFromApi";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import OptionalDropDown from "../Scaffolding/OptionalDropDown";
import ButtonLoader from "../../utils/components/ButtonLoader";


export const MEASURES_PERIOD_RELEASE_DAG_ID = 'measures_period_release_bq'
export const PRODUCE_MEASURES_PERIOD_RELEASE_DAG_ID = 'generate_plu_measures_for_outlet'

export const MeasuresTriggerDag = () => {
    const [showBetaCheckbox, updateShowBetaCheckbox] = useState(false);
    const [measuresRetailerIds, updateMeasuresRetailerIds] = useState('');
    const [useRetailerIds, updateUseRetailerIds] = useState(false);
    const [measuresRunPrefix, updateMeasuresRunPrefix] = useState('');
    const [usePrefix, updateUsePrefix] = useState(false);
    const [period, updatePeriod] = useState(undefined);
    const [endingWeekId, updateEndingWeekId] = useState(undefined);
    const [useStartingWeekId, updateUseStartingWeekId] = useState(false);
    const [startingWeekId, updateStartingWeekId] = useState(undefined);
    const [insightsStartDate, updateInsightsStartDate] = useState(undefined);
    const [outletSelection, updateOutletDropdown] = useState(undefined);
    const [useGeographyComposition, updateUseGeographyComposition] = useState(false);
    const [geographyCompositionSelection, updateGeographyCompositionDropdown] = useState(undefined);
    const [snapshotSelection, updateSnapshotDropdown] = useState(undefined);
    const [snapshotOptions, updateSnapshotOptions] = useState([]);
    const [projectionsVersionSelection, updateProjectionsVersionDropdown] = useState(undefined);
    const [projectionsVersionOptions, updateProjectionsVersionOptions] = useState([]);
    const [geographyCompositionOptions, updateGeographyCompositionOptions] = useState([]);
    const [periodsOptions, updatePeriodsOptions] = useState([]);
    const [marketSizeInfoVersionSelection, updateMarketSizeInfoVersionDropdown] = useState(undefined);
    const [storesServiceVersionSelection, updateStoresServiceVersionSelection] = useState(undefined);
    const [storesServiceVersionOptions, updateStoresServiceVersionOptions] = useState([]);
    const [marketSizeInfoVersionOptions, updateMarketSizeInfoVersionOptions] = useState([]);
    const [filterOutInactiveItems, updateFilterOutInactiveItems] = useState(true);
    const [saveInputsForRun, updateSaveInputsForRun] = useState(false);

    const [checkpointPath, updateCheckpointPath] = useState(undefined)
    const [beta, setBeta] = useState(false);
    const [isProduce, setIsProduce] = useState(false);
    const [loading, setSetLoading] = useState(true);

    const getDagToProcess = () =>  isProduce ? PRODUCE_MEASURES_PERIOD_RELEASE_DAG_ID : MEASURES_PERIOD_RELEASE_DAG_ID
    function onSubmitButtonClicked() {
        const data = {
            outlet: outletSelection.outletName,
            outlet_id: outletSelection.value,
            snapshot_period: snapshotSelection,
            markets_period: period,
            measures_run_prefix: usePrefix ? measuresRunPrefix : undefined,
            ending_week_id_filter: Number(endingWeekId),
            starting_week_id_filter: useStartingWeekId ? Number(startingWeekId) : undefined,
            projections_version: projectionsVersionSelection,
            stores_version: storesServiceVersionSelection,
            market_size_info_version: marketSizeInfoVersionSelection,
            geography_composition: useGeographyComposition ? geographyCompositionSelection : undefined,
            filter_out_inactive_items: filterOutInactiveItems,
            retailer_ids: useRetailerIds ? measuresRetailerIds : undefined,
            checkpoint_source_path: checkpointPath,
            is_beta: beta,
            should_store_inputs: saveInputsForRun
        };

        const dagToRun = getDagToProcess();
        invokeDAGviaApiPost(dagToRun, data)
            .then((response) => {
                if (response?.apiError)
                    toastError(toast, `${dagToRun} DAG trigger failed with error ${JSON.stringify(response.apiError)}`)
                else
                    toastSuccess(toast, `${dagToRun} DAG triggered successfully with response ${JSON.stringify(response)}`)
            })
            .catch(e => {
                toastError(toast, `${dagToRun} DAG trigger failed with error ${JSON.stringify(e)}`)
            })
    }


    React.useEffect(() => {
        setSetLoading(true);
        Promise.all([
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/productSnapshotVersions`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/projectionsVersions`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/storesVersions`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/marketSizeInfoVersions`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/geographyComposition`),
            makeApolloClientCall(currentPeriodQuery),
            makeApolloClientCall(insightsStartDateQuery),
            makeApiCallForPeriods(),
            isMeasuresBetaAvailable()
        ])
            .then(([
                       productSnapshotVersionsResults,
                       projectionsVersionsResults,
                       storesVersionsResults,
                       marketSizeInfoVersionsResults,
                       geographyCompositionResults,
                       currentPeriodQueryResults,
                       insightsStartDateQueryResults,
                       periodsResults,
                       isMeasuresBetaAvailableResults
                   ]) => {

                const options = productSnapshotVersionsResults.sort().reverse();
                updateSnapshotOptions(addLabelToOptionsIfNotExist(options));

                updateProjectionsVersionDropdown(projectionsVersionsResults[0])
                updateProjectionsVersionOptions(addLabelToOptionsIfNotExist(projectionsVersionsResults));

                updateStoresServiceVersionSelection(storesVersionsResults[0]);
                updateStoresServiceVersionOptions(addLabelToOptionsIfNotExist(storesVersionsResults));

                updateMarketSizeInfoVersionDropdown(marketSizeInfoVersionsResults[0])
                updateMarketSizeInfoVersionOptions(addLabelToOptionsIfNotExist(marketSizeInfoVersionsResults));

                const record = currentPeriodQueryResults.records[0];
                if (!endingWeekId) {
                    updateEndingWeekId(record.weekId);
                }
                if (!snapshotSelection) {
                    updateSnapshotDropdown(record.legacyPeriod.toString())
                }

                updateInsightsStartDate(insightsStartDateQueryResults.records[0]?.firstWeekOfPeriod.endDate);
                updateStartingWeekId(insightsStartDateQueryResults.records[0]?.firstWeekOfPeriod.weekId);

                updateShowBetaCheckbox(isMeasuresBetaAvailableResults)

                const geographyCompositionMapped = geographyCompositionResults.data.map(geographyComposition => {
                    const geoComposition = geographyComposition.name.toString();
                    return {label: geoComposition, value: geoComposition};
                })
                updateGeographyCompositionOptions(geographyCompositionMapped)

                const periodsMap = labelCurrentPeriodWeek(periodsResults)
                updatePeriodsOptions(periodsMap)
                setSetLoading(false);
            })
            .catch((error) => {
                console.error(error)
                this.setState({loading: false})
            })
            .finally(() => setSetLoading(false))

    }, []);

    function isSubmitButtonDisabled() {
        return !outletSelection || !period || !snapshotSelection || !endingWeekId;
    }

    return (
        <Page name={'Trigger Measures'}>
            <LoadingWrapper loading={loading}>
                <div className={'dag-trigger-group'}>
                    <div className={'switches-radio'}>
                        <div id={'check-boxes'}>
                            <Checkbox
                                id={'produce_measures'}
                                labelText={"Produce"}
                                onChange={() => {
                                    setIsProduce(!isProduce);
                                    updateSaveInputsForRun(false)
                                }}
                                checked={isProduce}/>
                        </div>
                        {!isProduce && showBetaCheckbox && <div id={'check-boxes'}>
                            <Checkbox id={'beta-checkbox'}
                                      checked={beta}
                                      labelText='Beta'
                                      onChange={() => setBeta(!beta)}
                            /></div>}
                        {!isProduce && <div id={'check-boxes'}><Checkbox
                            id={'save_inputs'}
                            labelText={'Save Inputs'}
                            onChange={() => updateSaveInputsForRun(!saveInputsForRun)}
                            checked={saveInputsForRun}/></div>}
                    </div>
                    {(<>
                        <div className={"custom-run-selections"}>
                            <div className={"custom-run-selections-column"}>
                                <div className={"custom-run-selections-row"}>
                                    <div className={"custom-run-selections-row-column"}>
                                        <ReactSelectDropdownFromApi
                                            onDropDownChange={(value) => updateEndingWeekId(value.value)}
                                            selectedDropDownValue={endingWeekId && endingWeekId.toString()}
                                            labelText={"End Week ID"}
                                            getData={() => makeApiCallForPeriods().then(response => labelCurrentPeriodWeek(response))}
                                        />
                                        <br/>
                                        <OutletsDropdown onChange={(option) => updateOutletDropdown(option)}
                                                         value={outletSelection?.value}/>
                                        <br/>
                                        <PeriodsDropdown
                                            id={"market_period"}
                                            className={'data-row-dropdown'}
                                            updatePeriod={updatePeriod}
                                            periodLabel={'Market Period'}
                                            selectedPeriod={period}
                                            graphqlQuery={'{ marketPeriodList }'}/>
                                        <br/>

                                        <SelectDropdown
                                            id={'stores-service-version_dropdown'}
                                            inputLabel={"Stores Service Version"}
                                            onChange={(option) => updateStoresServiceVersionSelection(option.value)}
                                            selectValue={storesServiceVersionSelection}
                                            options={storesServiceVersionOptions}
                                            placeholder={'Select Stores Services Version...'}
                                        />
                                        <br/>
                                        <SelectDropdown
                                            id={'market-size-info-version_dropdown'}
                                            inputLabel={"Market Size Info Version"}
                                            onChange={(option) => updateMarketSizeInfoVersionDropdown(option.value)}
                                            selectValue={marketSizeInfoVersionSelection}
                                            options={marketSizeInfoVersionOptions}
                                            placeholder={'Select Market Size Info Version...'}
                                        />
                                        <br/>
                                        <div className={'id-input width-450'}>
                                            <OptionalDropDown
                                                checkboxText={'Use an optional Start Week ID for the dataset you create'}
                                                labelText={'Start Week ID'}
                                                helpText={`Defaults to the first week of the"insights start" period: ${insightsStartDate}`}
                                                selectedDropDownValue={getValueFromOptions(startingWeekId && startingWeekId.toString(), periodsOptions)}
                                                checkBoxIsChecked={useStartingWeekId}
                                                onCheckBoxChange={updateUseStartingWeekId}
                                                onDropDownChange={updateStartingWeekId}
                                                dropDownValues={periodsOptions}
                                                placeholder={"Select..."}
                                            />
                                        </div>
                                        <br/>

                                    </div>
                                </div>
                                {!isProduce && <div className={"custom-run-selections-row"}>
                                    <div className={"custom-run-selections-row-column"}>
                                        <SelectDropdown
                                            id={'snapshot_dropdown'}
                                            inputLabel={"Snapshot"}
                                            onChange={(option) => updateSnapshotDropdown(option.value)}
                                            selectValue={snapshotSelection}
                                            options={snapshotOptions}
                                            placeholder={'Select Product Snapshot...'}
                                        />
                                        <br/>
                                    </div>
                                </div>}
                            </div>
                            <div className={"custom-run-selections-column"}>
                                <div className={"custom-run-selections-row"}>
                                    <div className={"custom-run-selections-row-column"}>
                                        <div className={"projections-version-row"}>
                                            <div className={'id-input'}>
                                                <span className='input-group-label'>Projections Version:</span>
                                                <Select className={'select-dropdown'}
                                                        classNamePrefix={'select-dropdown'}
                                                        id={'projections-version_dropdown'}
                                                        onChange={(option) => updateProjectionsVersionDropdown(option.value)}
                                                        value={getValueFromOptions(projectionsVersionSelection, projectionsVersionOptions)}
                                                        options={projectionsVersionOptions}
                                                        placeholder={'Select Projections Version...'}
                                                />
                                            </div>
                                            <ButtonLoader
                                                buttonTitle={"Get Test Projections"}
                                                buttonHasModal
                                                buttonClassName={"button primary get-test-projections-submit"}
                                                modalText={"Are you sure you want to get test projections?"}
                                                axiosFunction={() => invokeDAGviaApiPost('get_metadata_keith', {})}
                                                toastTextFailure={"get_metadata_keith DAG trigger failed with error"}
                                                toastTextSuccess={"get_metadata_keith DAG triggered successfully with response"}
                                            />
                                        </div>
                                        <LastKeithFilesRun/>
                                        <br/>
                                    </div>
                                </div>
                                {!isProduce && <div className={"custom-run-selections-row"}>
                                    <div className={"custom-run-selections-row-column"}>
                                        <div className={'measures-item-filters'}>
                                            <span className='input-group-label'>Item Filters:</span>
                                            <div id={'check-boxes'}>
                                                <Checkbox
                                                    id={'inactive_items'}
                                                    labelText={'Include SN/SA/SG Items Only'}
                                                    onChange={updateFilterOutInactiveItems}
                                                    checked={filterOutInactiveItems}/>
                                            </div>
                                        </div>
                                        <br/>
                                        <OptionalTextBox
                                            checkboxText={`Use an optional prefix for the dataset you create`}
                                            labelText={'Measures Prefix'}
                                            helpText={'Optional prefix for measures dataset'}
                                            onCheckBoxChange={updateUsePrefix}
                                            onTextBoxChange={(value) => updateMeasuresRunPrefix(prefixFilterRules(value))}
                                            checkBoxIsChecked={usePrefix}
                                            textInput={measuresRunPrefix}
                                        />
                                        <br/>
                                        <div className={'id-input width-450'}>
                                            <OptionalDropDown checkboxText={'Use an optional Geography Composition'}
                                                              labelText={'Geography Composition'}
                                                              helpText={'Optional Geography Composition'}
                                                              selectedDropDownValue={getValueFromOptions(geographyCompositionSelection, geographyCompositionOptions)}
                                                              checkBoxIsChecked={useGeographyComposition}
                                                              onCheckBoxChange={updateUseGeographyComposition}
                                                              onDropDownChange={updateGeographyCompositionDropdown}
                                                              dropDownValues={geographyCompositionOptions}
                                                              placeholder={"Select..."}
                                            />
                                        </div>
                                        <br/>
                                        <div>
                                            <OptionalTextBox
                                                labelText={'Retailer IDs'}
                                                checkboxText={'Input a comma separated list of Retailer Ids'}
                                                checkBoxIsChecked={useRetailerIds}
                                                textInput={measuresRetailerIds}
                                                onCheckBoxChange={updateUseRetailerIds}
                                                onTextBoxChange={(value) => updateMeasuresRetailerIds(value && value.replace(/[^\d,]/g, ''))}
                                            />
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </>)}
                    <div className={"measures-checkpoint-container"}>
                        <CheckpointDropdown
                            updateCheckpointPath={({checkpointPath}) => updateCheckpointPath(checkpointPath)}
                            datasets={['cleanse_fill_extract', 'filtered_promo', 'promo_extract', 'store_insights']}
                            tableData={['promo_parquet']}
                            id={'checkpointdropdown'}
                        />
                        <br/>
                    </div>
                    <div className={"trigger-dag-submit-button-container"}>
                        <button type="button"
                                disabled={isSubmitButtonDisabled()}
                                className="button primary measures-trigger-submit"
                                onClick={onSubmitButtonClicked}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </LoadingWrapper>
            <br/>
            <AirflowDagLogs
                processName={getDagToProcess()}
                includeDagName={false}
                customColumns={[
                    {header: 'Outlet', accessorKey: 'conf.outlet', className: 'non-numeric-field'},
                    {header: 'Outlet ID', accessorKey: 'conf.outlet_id', className: 'non-numeric-field'},
                    {header: 'Period', accessorKey: 'conf.markets_period', className: 'non-numeric-field'}
                ]}
            />
        </Page>
    );
}

export default MeasuresTriggerDag;
