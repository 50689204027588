import _ from "lodash";
import moment from "moment-timezone";
import {CHICAGO_TIMEZONE} from "../Dashboard/Alerts/MissingData/filters";

export function getNicelyFormattedNumber(value) {
    if(_.isNil(value)) {
        return ""
    }
    return (typeof value === 'string' ? Number(value) : value).toLocaleString('en-US');
}

export function convertDateTime(value, format) {
    moment.tz.setDefault(CHICAGO_TIMEZONE)
    const date = moment(value, "YYYY-MM-DDThh:mm:ss Z");
    return date.isValid() ? date.format(format || "YYYY-MM-DD kk:mm") : value;
}

export function convertValueToCentral(value) {
    return moment(value).tz(CHICAGO_TIMEZONE)
}
