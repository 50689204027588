import React from 'react';
import PropTypes from 'prop-types';
import {Popover} from "react-tiny-popover";

const ReactTablePopoverMenu = ({title, id, children, isPopOverOpen, setIsPopOverOpen}) => {

    return (
        <Popover
            isOpen={isPopOverOpen}
            positions={[ "left", "right"]}
            align="start"
            containerClassName={"react-table-popover-wrapper"}
            content={() => (<div className="popover-content">{children}</div>)}
        >
            <div onClick={() => setIsPopOverOpen(!isPopOverOpen)} className="btn-container">
                <button
                    type={"button"}
                    data-toggle={`popover-row-dropdown-${id}`}
                    className={"button secondary hollow dropdown hover popover-menu-button"}
                >
                    {title}
                </button>
            </div>
        </Popover>
    );
};

ReactTablePopoverMenu.propTypes = {
    children: PropTypes.any,
    id: PropTypes.string,
    title: PropTypes.string,
    isPopOverOpen: PropTypes.bool,
    setIsPopOverOpen: PropTypes.func,
};

export default ReactTablePopoverMenu;
