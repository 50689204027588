import React from "react";
import {Row} from "react-foundation";
import PropTypes from "prop-types";
import {v4 as uuidv4} from "uuid";
import classnames from "classnames";
import {addLabelToOptionsIfNotExist} from "../../utils/maybeAddLabelToOptions";
import Select from "react-select";
import {convertLabelTextToDataTestId} from "../../utils/testHelpers/assertUtils";

export const OptionPropType = PropTypes.shape({
    label: PropTypes.node.isRequired,
    value: PropTypes.string.isRequired
})

const OptionalDropDown = ({
                              onCheckBoxChange,
                              onDropDownChange,
                              checkBoxIsChecked,
                              checkboxText,
                              labelText,
                              helpText,
                              dropDownValues,
                              selectedDropDownValue,
                              optionalClassName,
                              id,
                              placeholder
                          }) => {
    const checkBoxId = `checkbox-${uuidv4()}`;

    const checkBoxChange = (isChecked) => {
        onCheckBoxChange(isChecked)
        if (!isChecked) {
            onDropDownChange(undefined)
        }
    }
    return (
        <Row>
            <div className="checkbox-container scaffolding-dag">
                <input className="checkbox test"
                       type="checkbox"
                       id={checkBoxId}
                       checked={checkBoxIsChecked}
                       onChange={() => checkBoxChange(!checkBoxIsChecked)}
                />
                <label className={'checkbox-label'}
                       htmlFor={checkBoxId} data-testid={'checkbox_label'}>{checkboxText}</label>
            </div>
            <div className={'id-input'} data-testid={"id-input"}>
                <span className='input-group-label'>{labelText}:</span>
                <Select
                    id={convertLabelTextToDataTestId(labelText, undefined, "_dropdown")}
                    placeholder={placeholder}
                    className={classnames("optional-dropdown", optionalClassName)}
                    classNamePrefix={"optional-dropdown"}
                    value={selectedDropDownValue}
                    options={addLabelToOptionsIfNotExist(dropDownValues)}
                    onChange={(option) => onDropDownChange(option ? option.value : undefined)}
                    isDisabled={!checkBoxIsChecked}
                />
            </div>
            <div className="help-text" data-testid={'help_text'}>{helpText}</div>
        </Row>
    );
}

OptionalDropDown.defaultProps = {
    placeholder: "Select..."
}

OptionalDropDown.propTypes = {
    onCheckBoxChange: PropTypes.func.isRequired,
    onDropDownChange: PropTypes.func.isRequired,
    checkBoxIsChecked: PropTypes.bool.isRequired,
    dropDownValues: PropTypes.oneOfType([
        PropTypes.arrayOf(OptionPropType),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.array
    ]).isRequired,
    selectedDropDownValue: PropTypes.object,
    checkboxText: PropTypes.string,
    labelText: PropTypes.string,
    helpText: PropTypes.string,
    optionalClassName: PropTypes.string,
    placeholder: PropTypes.string,
}

export default OptionalDropDown