import {verifyNonNullableFieldsExistInConfig} from './verifyNonNullableFieldsExistInConfig';
import {verifyFileTypeConfigs} from './verifyFileTypeConfigs';
import _ from "lodash";
import {
    getUserTypeFromLocalToken,
    LOCATOR_INGESTION_USERS,
    RETAILER_INGESTION_USERS
} from '../../auth/accessTokenValidator';
import RetailerIdWithOnboarding from "../IngestionLogs/Columns/RetailerIdWithOnboarding";

export class ConfigType {
    name;
    partition;
    allowedUserTypes;

    constructor(name, allowedUserTypes) {
        this.name = name;
        this.partition = name;
        this.allowedUserTypes = allowedUserTypes;
    }

    getName() {
        return this.name;
    }

    getPartition() {
        return this.partition;
    }

    shouldSaveConfig() {
        return true;
    }

    getFileTypeName() {
        return _.startCase(_.toLower(this.name + ' Mapping'))
    }

    getConfigMappingKey() {
        return _.toLower(this.name + '_mappings')
    }

    isAuthorized() {
        return this.allowedUserTypes.includes(getUserTypeFromLocalToken());
    }
}

export class SalesConfig extends ConfigType {
    constructor () {
        super('sales', RETAILER_INGESTION_USERS)
        this.partition = 'normalized';
    }

    shouldSaveConfig(parsedConfig) {
        const hasAllRequiredFields = verifyNonNullableFieldsExistInConfig(parsedConfig)
        if (!hasAllRequiredFields) {
            alert(
                'Warning:\n' +
                'The sales configs you tried to save does not have all required fields configured correctly.\n\n' +
                'The following fields are required:\n' +
                '* "item_code"\n' +
                '* "sales_units"\n' +
                '* One of: "sales_amount" OR "price"\n' +
                '* One of: "sales_end_date" OR "file_date_format"'
            );
        }
        return hasAllRequiredFields
    }
}

export class FileTypeMapping extends ConfigType {
    name = 'file_type';

    async shouldSaveConfig(parsedConfig, ftp, originalConfig) {
        const configCheckResults = await verifyFileTypeConfigs(parsedConfig.sales_mappings, ftp)
        const missingConfigs = configCheckResults?.filter(configs => !configs.fileExists).map(missingFile => missingFile.fileName)

        if (missingConfigs && missingConfigs.length) {
            alert(`Warning:\nThe following sales configs do not exist:\n${missingConfigs.join('\n')}\n\nYour changes will be saved, but please create them in the Sales Mapping menu, or remove them from the File Type Mapping.`);
        }

        const originalValueSalesMappings = originalConfig?.sales_mappings;

        const deletedConfigs = originalValueSalesMappings && originalValueSalesMappings.filter(original => {
                return !parsedConfig.sales_mappings.some(newMapping => newMapping.filename === original.filename)
            }
        )

        const deletedConfigsCheckResults = await verifyFileTypeConfigs(deletedConfigs, ftp);

        const manifestAlertArray = deletedConfigsCheckResults && deletedConfigsCheckResults.filter(config => config.manifestCount > 0)
            .map(manifest => `${manifest.fileName} has ${manifest.manifestCount} usages in manifest`);
        if (manifestAlertArray?.length > 0)
            alert(`Warning:\nThe following sales configs were deleted, but are still being used in the manifest (i.e. there are sales files that are specifically using the sales config):\n${manifestAlertArray.join('\n')}\n\nYour changes will be saved, but please re-add the sales config to the File Type Mapping or update the sales files to use a different sales config.`)

        return true;
    };
}


const stacklineRetailers = {1: 'Amazon.com'};


export const retailerIdDisplay = (salesforceRetailers, retailerId, row) => {
    return (row?.original?.fileType === CONFIG_TYPES.STACKLINE_SALES.name) ? (stacklineRetailers[retailerId] || 'Unknown') : <RetailerIdWithOnboarding salesforceRetailers={salesforceRetailers} retailerId={retailerId} />
}

export const CONFIG_TYPES = {
    FILE_TYPE: new FileTypeMapping(),
    SALES: new SalesConfig(),
    ITEMS: new ConfigType('items', RETAILER_INGESTION_USERS),
    STORES: new ConfigType('stores', RETAILER_INGESTION_USERS),
    TLOG: new ConfigType('tlog', RETAILER_INGESTION_USERS),
    LOCATORS: new ConfigType('locators', LOCATOR_INGESTION_USERS),
    STACKLINE_SALES: new ConfigType('stackline_sales', RETAILER_INGESTION_USERS),
};