import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {serviceRetailerDropdown} from "../../utils/retailerDisplayFormatter";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {INDEX_FIELD, RETAILER_ID_FIELD, RETAILER_ID_NAME_COLUMN} from "../Components/ReactTable/common_custom_columns";
import Page from "../Components/Page";
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {Breadcrumbs} from "../Components/Breadcrumbs";
import {EditFormBooleanDropdownInputField, EditFormDropdownInputField} from "../Components/ReactTable/EditRecordForm";
import {makeApolloClientCall} from "../../Api/makeApolloApiCall";
import moment from "moment";
import 'moment-timezone'
import {getUserEmail} from "../../auth/accessTokenValidator";
import CollapsableContent from "../Components/CollapsableContent";
import {makeApiCall} from "../../Api/makeApiCall";
import _ from "lodash";
import {getPriorityRetailerHistory} from "./GetPriorityRetailerHistory";


export function sortDataBydataQCWeekId(data) {
    if (data)
        data.sort((a, b) => {
            return a.qcWeek - b.qcWeek;
        })
    return data;
}

const PriorityRetailers = ({displayHelmetHeader}) => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/priority_retailers/`
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});

    const [periodOptions, updatePeriodOptions] = useState([]);
    const [defaultWeekId, setDefaultWeekId] = useState();
    const [isLoading, updateIsLoading] = useState(true);
    const [retailers, setRetailers] = useState([]);
    const [receiptLag, setReceiptLag] = useState([]);
    const SLD_PRIORITY_RETAILER_COLUMNS = [
        RETAILER_ID_NAME_COLUMN,
        {
            header: "Rollup Day", filterable: true,
            cell: ({row}) => {
                const rollupday = _.find(retailers.data, {retailerId: row.original.retailerId})?.rollUpDayOfWeek
                return <div>{rollupday}</div>
            }
        },
        {
            header: "Receipt Lag", filterable: true,
            cell: ({row}) => {
                const days = _.find(receiptLag.data, {retailerId: row.original.retailerId})?.receiptLag
                return <div>{days}</div>
            }
        },
        {header: "QC Approved", accessorKey: "qcApproved"},
        {header: "QC Week id", accessorKey: "qcWeek"},
        {header: "QC User", accessorKey: "qcUser"},
        {header: "Date of QC", accessorKey: "dateOfQc"},
        {
            header: "QC History", cell: ({row}) => {
                const expandedContent = getPriorityRetailerHistory(row.original.history);
                return <CollapsableContent expandedContent={expandedContent} collapsedContent={"show history"}/>;
            }, size: 400
        }
    ]

    useEffect(() => {
        updateIsLoading(true)
        Promise.all([
            makeApolloClientCall(` { periods { weekId endDate isCurrentPeriodWeek } } `),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/retailers/`),
            makeApiCall(`${process.env.REACT_APP_EDP_API_BASE_URL}/receiptLag/`)
        ])
            .then(([
                       periods, retailerRollup, receiptLagDays
                   ]) => {
                updatePeriodOptions(periods.periods.map((week) => ({value: week.weekId, label: week.endDate})));
                setDefaultWeekId(periods.periods.find(item => item.isCurrentPeriodWeek === true).weekId)
                setReceiptLag(receiptLagDays)
                setRetailers(retailerRollup)
            }).finally(() => {
            updateIsLoading(false)
        });


    }, []);

    function cleanUpAndSubmitData(currentData) {
        if (currentData["qcApproved"] !== undefined) {
            currentData["qcUser"] = getUserEmail()
            const inp = Date.now();
            currentData["dateOfQc"] = moment(inp).tz("America/Chicago").format('MM/DD/YYYY hh:mm:ss')
            const update = {
                "user": currentData["qcUser"],
                "date": currentData["dateOfQc"],
                "qcApproved": currentData["qcApproved"],
                "week": currentData["qcWeek"]
            };
            if (currentData["history"] === undefined) {
                currentData["history"] = [update]
            } else {
                currentData["history"].unshift(update)
            }

        }
        onRowEditConfirmed(currentData)
    }



    return (<Page name={"Priority Retailers: Store Insights"} displayHelmetHeader={displayHelmetHeader}
                  breadCrumbs={<Breadcrumbs
                      items={[{label: "Configurations"}, {label: "Retailer"}, {label: "Priority: Store Insights"}]}/>}>
        <LoadingWrapper loading={loading || isLoading}>
            <EditableReactTableV2
                filterable
                data={sortDataBydataQCWeekId(data.data)}
                columns={SLD_PRIORITY_RETAILER_COLUMNS}
                editTable={[INDEX_FIELD,
                    RETAILER_ID_FIELD(serviceRetailerDropdown().data),
                    {id: "qcApproved", Component: args => <EditFormBooleanDropdownInputField {...args} />},
                    {id: "qcWeek", defaultValue: defaultWeekId, Component: args => <EditFormDropdownInputField  {...args} options={periodOptions}/>},
                ]}
                onRowEditConfirmed={cleanUpAndSubmitData}
                onRowDeleteConfirmed={onRowDeleteConfirmed}
            />
        </LoadingWrapper>
    </Page>);
}

PriorityRetailers.defaultProps = {
    displayHelmetHeader: true,
}

PriorityRetailers.propTypes = {
    displayHelmetHeader: PropTypes.bool,
}

export default PriorityRetailers;