import moment from "moment";
import 'moment-timezone'
import React from "react";

export const getPriorityRetailerHistory = (history) => {
    function getMoment(auditLog) {
        return moment(auditLog.date, 'MM/DD/YYYY hh:mm:ss');
    }

    function getLastFourWeeks(auditLog) {
        if (moment(auditLog.date).format('MM/DD/YYYY') === moment('02/14/2025').format('MM/DD/YYYY')) {
            return false
        }

        return getMoment(auditLog) >= moment(Date.now()).tz("America/Chicago").add(-4, "week");
    }

    return history ? <ul style={{listStyleType: "none", textWrap: "auto"}}>
        {
            history.filter((auditLog) => getLastFourWeeks(auditLog))
                .sort((a, b) => getMoment(b) - getMoment(a))
                .map((auditLog, key) => {
                    return <li key={key}>{auditLog.date}: <b>{auditLog.user}</b> set qc {auditLog.qcApproved.toString()} for <b>{auditLog.week}</b></li>
                    }
                )}
    </ul> : <div> {'No QC History'} </div>;
}