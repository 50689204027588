import React from 'react';
import PropTypes from 'prop-types';

function ThresholdSelection({dollarValue, dollarOnChange, unitValue, unitOnChange}) {
    return <div className={"threshold-selection"}>
        <div className={"threshold-field"} data-testid="threshold-dollars">
            <span>Dollars</span>
            <input
                id={"threshold-dollars"}
                type={"number"}
                value={dollarValue}
                onChange={dollarOnChange}
            />
        </div>
        <div className={"threshold-field"} data-testid="threshold-units">
            <span>Units</span>
            <input
                id={"threshold-units"}
                type={"number"}
                value={unitValue}
                onChange={unitOnChange}
            />
        </div>
    </div>;
}

ThresholdSelection.propTypes = {
    dollarOnChange: PropTypes.func,
    dollarValue: PropTypes.number,
    unitOnChange: PropTypes.func,
    unitValue: PropTypes.number
};

export default ThresholdSelection;