import ButtonLoader from "../../utils/components/ButtonLoader";
import {makeApiCallPost} from "../../Api/makeApiCall";
import React from "react";
import PropTypes from "prop-types";
import {createDagRunConf} from "../../Api/dagRunConf";

export const CleanseSalesToParquetButton = ({retailerId, setIsPopOverOpen}) => <ButtonLoader
    buttonTitle={"Sales: Cleanse to Parquet"}
    axiosFunction={makeApiCallPost}
    postBody={{
        dagName: "cleansed_sales_to_parquet",
        data: createDagRunConf({
            retailer_id: retailerId.toString(),
            publish_gcs_url: `gs://${process.env.REACT_APP_GCS_DATA_BASE_URL}`
        })
    }}
    buttonClassName={"button tertiary"}
    url={`${process.env.REACT_APP_EDP_API_BASE_URL}/dagTrigger`}
    toastTextFailure={`Sales: Cleanse to Parquet has failed to kicked off.`}
    toastTextSuccess={`Sales: Cleanse to Parquet has successfully kicked off.`}
    onClose={setIsPopOverOpen}
    onSubmitCallback={setIsPopOverOpen}
/>;

CleanseSalesToParquetButton.propTypes = {
    retailerId: PropTypes.number.isRequired,
    setIsPopOverOpen: PropTypes.func
}